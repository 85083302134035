// NOTE: クラウドベースでスタイルを管理するための ID
// https://console.cloud.google.com/google/maps-apis/studio/maps/999a4477c9c7571?project=estimate-251803
export const MAP_ID = "999a4477c9c7571" as const
// NOTE: 日本近辺の1kmあたり緯度経度
// https://easyramble.com/latitude-and-longitude-per-kilometer.html
export const LAT_PER_KM = 0.0090133729745762 as const
export const LNG_PER_KM = 0.010966404715491394 as const
// NOTE: 査定物件から±どれくらいの範囲の物件リストを取得するかの範囲
export const HORIZONTAL_RANGE_KM = 0.6 as const
export const VERTICAL_RANGE_KM = 0.6 as const
export const URL_DELIVERY_DEFAULT_LIMIT = 1000 as const
export const PROPERTIES_MAIL_MAX_NUM = 6 as const
export const URL_SHARE_PROPERTIES_MAX_NUM = 4 as const
export const SYSTEM_ERROR_CHANGE_THRESHOLD = 20 as const
export const MINIMUM_ADDITIONAL_LOAD_DISTANCE_METER = 600 as const
export const EVENT_BUS_TYPE = {
  UPDATE_CLIENTS: "updateClients",
  SEND_CLIENT_EMAIL: "sendClientEmail",
  DELETE_CLIENTS: "deleteClients",
  REGISTER_CLIENTS: "registerClients",
} as const
export const MAX_ZOOM = 20 as const
export const MIN_ZOOM = 7 as const
export const INITIAL_ZOOM = {
  PC: 15,
  SP: 16,
} as const
export const CLUSTER_GRID_SIZE_METER = 200 as const // 200メートルのグリッドごとにクラスター化する
export const MAX_DAILY_MAIL = 4999
export const MAIL_TYPES = {
  UPDATED_PROPERTIES_PRICE: "updatedPropertiesPrice",
  CATCH_UP_PROPERTIES: "catchUpProperties",
  CATCH_UP_RENT_PROPERTIES: "catchUpRentProperties",
  CLIENT_ORIGINAL: "clientOriginal",
  HANKYO_REPLY_SELL: "hankyoReplySell",
  HANKYO_REPLY_BUY: "hankyoReplyBuy",
  CONSULTATION_DATA_REQUEST: "consultationDataRequest",
  CONSULTATION_PREVIEW_RESERVATION: "consultationPreviewReservation",
  CONSULTATION_QUESTION: "consultationQuestion",
  AMAZON_GIFT_SELL: "amazonGiftSell",
  AMAZON_GIFT_BUY: "amazonGiftBuy",
  MORTGAGE_EXAMINATION_RECOMMEND: "mortgageExaminationRecommend",
  SELL_CLIENT_SITE_GUIDE: "sellClientSiteGuide",
  LOAN_CLIENT_SITE_GUIDE: "loanClientSiteGuide",
  BUY_CLIENT_SITE_GUIDE: "buyClientSiteGuide",
  SELL_CLIENT_MAIL_MAGAXZINE: "sellClientMailMagazine",
  // ユーザー招待メール
  INVITE_USER: "inviteUser",
  MARKET_MAIL: "marketMail",
  BREAKDOWN_MAIL: "breakdownMail",
  DISTRIBUTION_MAIL: "distributionMail",
  BUYER_MAIL: "buyerMail",
} as const
export const MAIL_SUB_TYPES = {
  NO_ACTIVE_CLIENT: "noActiveClient",
} as const
export const MAIL_TYPES_JA = {
  UPDATED_PROPERTIES_PRICE: "査定更新提案 [取引種類：売却]",
  MARKET_MAIL_SELL: "マーケットレポート [取引種類：売却]",
  CATCH_UP_PROPERTIES: "物件提案新着 [取引種類：購入]",
  CATCH_UP_RENT_PROPERTIES: "物件提案新着 [取引種類：賃貸]",
  HANKYO_REPLY_SELL: "自動応答 [取引種類：売却]",
  HANKYO_REPLY_BUY: "自動応答 [取引種類：購入]",
  HANKYO_REPLY_RENT: "自動応答 [取引種類：賃貸]",
  CLIENT_ORIGINAL: "テキスト",
  CONSULTATION_DATA_REQUEST: "購入・ピンリッチ内資料請求",
  AMAZON_GIFT_SELL: "売却・Amazonギフト券3万円",
  AMAZON_GIFT_BUY: "購入・Amazonギフト券3000円",
  MORTGAGE_EXAMINATION_RECOMMEND: "購入・住宅ローン事前審査",
  BUYER_MAIL: "潜在的な買主がいます",
  MARKET_MAIL: "周辺の不動産市況",
  DISTRIBUTION_MAIL: "周辺の面積別価格の分布",
  BREAKDOWN_MAIL: "周辺の面積別価格の内訳",
  BUY_CLIENT_GUIDE: "検索サイト案内",
  SELL_CLIENT_GUIDE: "AI査定案内",
  LOAN_SELL_CLIENT_GUIDE: "住宅ローン借換",
  LOAN_BUY_CLIENT_GUIDE: "住宅ローン新規借入",
} as const
export const MAIL_TYPES_DESCRIPTION = {
  UPDATED_PROPERTIES_PRICE: {
    UPDATE_DATE: "2022年2月23日",
    SUBJECT: " {建物名or住所名｝の周辺の売却物件 - ｛差出人名｝（｛会社名｝）",
    EXPLANATION:
      "取引種別：売却の顧客に新着の売出情報と成約情報を配信する。所有物件の価格が知りたいが、売却動機が顕在化していない売主に対して、売出価格に影響のある競合物件の売出情報と成約情報を当て続け、御社の認知を獲得し、売却動機が顕在化したタイミングでの再査定＆訪問査定の獲得を目指す。",
    SEND_TRIGGER: [
      "下記の条件に沿う物件情報がある場合は、毎日17時前後に送信する",
      "- 物件フィルターにかかる新規の売出情報が出現",
      "- 物件フィルターにかかる新規の成約情報が出現",
      "- 物件フィルターにかかる既存の売出情報に値下げがある",
    ],
    EXPECTATION: "訪問査定の獲得",
    DEAL_TYPE: "売却",
    DEAL_PHASE: "追客中",
  },
  CATCH_UP_PROPERTIES: {
    UPDATE_DATE: "2022年1月13日",
    SUBJECT:
      "新着｛●｝件 ｛物件名｝, ｛価格｝, ｛広さ｝ほか  - ｛差出人名｝（｛会社名｝）",
    EXPLANATION:
      "取引種別：購入の顧客に新着の売出情報のみを配信する。主要メディアと同水準のメール配信数を維持し、買主の御社への認知を獲得し、長期検討している買主から詳細条件のヒアリングを目指す。",
    SEND_TRIGGER: [
      "下記の条件に沿う物件情報がある場合は、毎日17時前後に送信する。",
      "- 顧客フィルターにかかる新規の売出情報が出現",
      "- 顧客フィルターにかかる既存の売出情報に値下げがある",
    ],
    EXPECTATION: "顧客の詳細要望シートの獲得",
    DEAL_TYPE: "購入",
    DEAL_PHASE: "追客中",
  },
  HANKYO_REPLY_SELL: {
    UPDATE_DATE: "2022年2月23日",
    SUBJECT: "{建物名or住所名} に関するご相談の件 - ｛差出人名｝（｛会社名｝）",
    EXPLANATION:
      "売却メディア経由で査定依頼等の反響メールを受信し、取引種類：売却で顧客が自動登録されたタイミングで自動配信する。訪問査定日の確保を目指す。",
    SEND_TRIGGER: "取引種類：売却で顧客が自動登録されたタイミング",
    EXPECTATION: "訪問査定の獲得",
    DEAL_TYPE: "売却",
    DEAL_PHASE: "追客中",
  },
  HANKYO_REPLY_BUY: {
    UPDATE_DATE: "2022年1月13日",
    SUBJECT: "｛物件名｝の資料請求 - ｛差出人名｝（｛会社名｝）",
    EXPLANATION:
      "メディアの反響メールを受信し、取引種類：購入で顧客が自動登録されたタイミングで配信する。見学希望日の獲得を目指す。",
    SEND_TRIGGER: "取引種類：購入で顧客が自動登録されたタイミング",
    EXPECTATION: "見学希望日の獲得",
    DEAL_TYPE: "購入",
    DEAL_PHASE: "追客中",
  },
  CONSULTATION_DATA_REQUEST: {
    UPDATE_DATE: "2022年1月13日",
    SUBJECT:
      "｛物件名｝の資料請求を頂きありがとうございました - （｛差出人名｝｛会社名｝）",
    EXPLANATION:
      "顧客がピンリッチの配信メールや検索サイトを利用し、資料請求したタイミングで、見学希望日をヒアリングする。",
    SEND_TRIGGER: "顧客がピンリッチのメール及び検索サイトで資料請求する",
    EXPECTATION: "見学希望日の獲得",
    DEAL_TYPE: "該当なし",
    DEAL_PHASE: "追客中",
  },
  // NOTE: 自動メールではないので設定は一旦不要。復活するかもしれないのでコメントアウトで残す
  // AMAZON_GIFT_SELL: {
  //   UPDATE_DATE: "2022年3月9日",
  //   SUBJECT:
  //     "",
  //   EXPLANATION:
  //     "",
  //   SEND_TRIGGER: "",
  //   EXPECTATION: "",
  //   DEAL_TYPE: "",
  //   DEAL_PHASE: ""
  // },
  // AMAZON_GIFT_BUY: {
  //   UPDATE_DATE: "2022年3月9日",
  //   SUBJECT: "",
  //   EXPLANATION: "",
  //   SEND_TRIGGER: "",
  //   EXPECTATION: "",
  //   DEAL_TYPE: "",
  //   DEAL_PHASE: ""
  // },
  // MORTGAGE_EXAMINATION_RECOMMEND: {
  //   UPDATE_DATE: "2022年3月9日",
  //   SUBJECT: "",
  //   EXPLANATION: "",
  //   SEND_TRIGGER: "",
  //   EXPECTATION: "",
  //   DEAL_TYPE: "",
  //   DEAL_PHASE: ""
  // }
} as const
export const MAIL_TYPES_TEMPLATE_PATH = {
  URL_SHARE: "updated_properties/index.ejs",
  UPDATED_PROPERTIES_PRICE: "updated_properties_price/index.ejs",
  CATCH_UP_PROPERTIES: "catch_up_properties/index.ejs",
  CATCH_UP_RENT_PROPERTIES: "catch_up_rent_properties/index.ejs",
  CONSULTATION_DATA_REQUEST: "consultation_data_request/index.ejs",
  HANKYO_REPLY_BUY: "hankyo_reply_buy/index.ejs",
  HANKYO_REPLY_SELL: "hankyo_reply_sell/index.ejs",
  HANKYO_REPLY_RENT: "hankyo_reply_rent/index.ejs",
  CONSULTATION_NOTIFY: "consultation_notify_mail/index.ejs",
  TYPE_FORM_NOTIFY: "user_notify_mail/index.ejs",
  AMAZON_GIFT_BUY: "amazon_gift_buy/index.ejs",
  AMAZON_GIFT_SELL: "amazon_gift_sell/index.ejs",
  MORTGAGE_EXAMINATION_RECOMMEND: "mortgage_examination_recommend/index.ejs",
  MARKET_MAIL_SELL: "market_mail/market_mail/index.ejs",
  MARKET_MAIL: "market_mail/market_mail/index.ejs",
  BREAKDOWN_MAIL: "market_mail/breakdown_mail/index.ejs",
  DISTRIBUTION_MAIL: "market_mail/distribution_mail/index.ejs",
  BUYER_MAIL: "market_mail/buyer_mail/index.ejs",
  LOAN_BUY_CLIENT_GUIDE: "mail_magazine/loan_client_site_guide/index.ejs",
  LOAN_SELL_CLIENT_GUIDE: "mail_magazine/loan_client_site_guide/index.ejs",
  BUY_CLIENT_GUIDE: "mail_magazine/buy_client_site_guide/index.ejs",
  SELL_CLIENT_GUIDE: "mail_magazine/sell_client_site_guide_ai/index.ejs",
} as const
export const MAIL_SUBJECTS = {
  URL_SHARE: "最新の物件情報をお送りします",
  UPDATED_PROPERTIES_PRICE:
    "【売却<%= properties.length %>件】<%= client.locations[0].location ? client.locations[0].location.replace(/[０-９0-9]+/g, match => '＊'.repeat(match.length)) : '' %>の周辺（<%= formattedDate %>）",
  CATCH_UP_PROPERTIES:
    "🎉【新着<%= properties.length %>件】<%= properties[0].contractPrice %>万円, <%= properties[0].locationName1 %><%= properties[0].town %>（<%= formattedDate %>）",
  CATCH_UP_RENT_PROPERTIES:
    "🎉【新着<%= properties.length %>件】<%= properties[0].contractPrice %>万円, <%= properties[0].locationName1 %><%= properties[0].town %>（<%= formattedDate %>）",
  CONSULTATION_DATA_REQUEST:
    "<%= client.clientName || 'お客' %>様、資料請求の件（<%= formattedDate %>）",
  CONSULTATION_NOTIFY: "PinRich - 資料請求",
  HANKYO_REPLY_BUY:
    "【<%= client.clientName ? `${client.clientName}様 ` : '' %>】資料請求の件（<%= userData.corporateName %>）（<%= formattedDate %>）",
  HANKYO_REPLY_SELL:
    "【<%= client.clientName ? `${client.clientName}様 ` : '' %>】ご相談の件（<%= userData.corporateName %>）（<%= formattedDate %>）",
  HANKYO_REPLY_RENT:
    "【<%= client.clientName ? `${client.clientName}様 ` : '' %>】資料請求の件（<%= userData.corporateName %>）（<%= formattedDate %>）",
  AMAZON_GIFT_SELL:
    "Amazonギフト券３万円を進呈【住宅売却を検討中の方限定】- <%= userData.userName %>（<%= userData.corporateName %>)",
  AMAZON_GIFT_BUY:
    "Amazonギフト券３０００円を進呈【住宅購入を検討中の方限定】- <%= userData.userName %>（<%= userData.corporateName %>)",
  MORTGAGE_EXAMINATION_RECOMMEND:
    "住宅ローンの事前審査はお済でしょうか？  - <%= userData.userName %>（<%= userData.corporateName %>)",
  TYPE_FORM_NOTIFY:
    "<%= typeFormKey == 'CATCH_UP_PROPERTIES' ? 'PinRich 物件提案の要望' : 'PinRich フォーム回答' %>",
  MARKET_MAIL_SELL:
    "<%= locationName %>周辺の不動産市況（<%= formattedDate %>）",
  MARKET_MAIL:
    "📈【<%= locationName %>】不動産マーケット情報（推移図）（<%= formattedDate %>）",
  BREAKDOWN_MAIL:
    "📊【<%= locationName %>】不動産マーケット情報（内訳図）（<%= formattedDate %>）",
  DISTRIBUTION_MAIL:
    "📉 【<%= locationName %>】不動産マーケット情報（分布図）（<%= formattedDate %>）",
  BUYER_MAIL:
    "🗺️【購入反響<%= buyerCount %>件】<%= locationName ? locationName.replace(/[０-９0-9]+/g, (match) => '＊'.repeat(match.length)) : '＊＊＊' %>の周辺（<%= formattedDate %>）",
  LOAN_BUY_CLIENT_GUIDE:
    "【購入検討者様向け】住宅ローン診断（<%= formattedDate %>）",
  LOAN_SELL_CLIENT_GUIDE: "住宅ローンのお借換え診断（<%= formattedDate %>）",
  BUY_CLIENT_GUIDE:
    "🥇【物件数No.1】 検索サイトのご案内（<%= formattedDate %>）",
  SELL_CLIENT_GUIDE: "🌐AI査定のご案内（<%= formattedDate %>）",
} as const
export const PROPERTY_TYPE = {
  HOUSE: "house",
  APARTMENT: "apartment",
} as const
export const PROPERTY_DATA_TYPE = {
  SALE: "sale",
  CONTRACT: "contract",
  RENT: "rent",
} as const
export const PROPERTY_DATA_TYPE_JA = {
  SALE: "売出",
  CONTRACT: "成約",
  RENT: "賃貸",
} as const
export const PROPERTY_DATA_TYPE_INFO_JA = {
  SALE: "売出情報",
  CONTRACT: "成約情報",
  RENT: "賃貸情報",
} as const
export const CONSULTATION_TYPE = {
  DATA_REQUEST: "dataRequest",
  PREVIEW_RESERVATION: "previewReservation",
  QUESTION: "question",
  VACANCY_INQUIRY: "vacancyInquiry",
} as const
export const CONSULTATION_CONTENT_TYPE = {
  SALE: "sale",
  BUY: "buy",
  OTHERS: "others",
} as const
export const SES_EVENT_MAIL_GROUP = {
  PROPERTIES: "properties",
  CONSULTATIONS: "consultations",
} as const
export const MQ_CONCURRENCY = 1 as const
export const MQ_QUEUE_NAMES = {
  CSV_UPLOAD: "csvUpload",
  SES_EVENT: "sesEvent",
  HANKYO_MAIL: "hankyoMail",
  // NOTE: BULLでQueueがwaitingのまま流れない状態になり、対策として一時的にQueueの名前を変更した
  // https://stackoverflow.com/questions/61504093/jobs-stuck-in-waiting-in-bull-queue-node-js
  CLIENT_EDIT: "clientBulkEdit",
  CLIENT_MAIL: "clientMail",
  CLIENT_LINE: "clientLine",
  CLIENT_SMS: "clientSms",
  CLIENT_USAGE_INFORMATION: "clientUsageInformation",
} as const
export const MQ_QUEUE_LABELS = {
  CSV_UPLOAD: "顧客アップロード",
  CLIENT_EDIT: "顧客編集",
  CLIENT_MAIL: "メール送信",
} as const
export const MQ_UPLOAD_STATUS = {
  IDLE: "idle",
  ACCEPTED: "accepted",
  PROCESSING: "processing",
  STOPPING: "stopping",
  FINISHED: "finished",
} as const
export const CSV_UPLOAD_STATUS_NAME = {
  IDLE: "空き状態",
  ACCEPTED: "処理開始待ち",
  PROCESSING: "処理中",
  STOPPING: "停止中",
  FINISHED: "完了",
} as const
export const CLIENT_DELIVERY_STATUS = {
  DELIVER: "deliver",
  STOPPED: "stopped",
  USER_STOPPED: "userStopped",
  REJECTED: "rejected",
  OVER_LIMIT: "overLimit",
  COMPLAINT: "complaint",
  SYSTEM_ERROR: "systemError",
} as const
export const CLIENT_DELIVERY_STATUS_NAME = {
  DELIVER: "配信中",
  STOPPED: "配信停止",
  USER_STOPPED: "配信拒否",
  REJECTED: "受信エラー",
  OVER_LIMIT: "上限超過",
  COMPLAINT: "迷惑判定",
  SYSTEM_ERROR: "システムエラー",
} as const
export const REDIS_KEY_PREFIX = {
  CSV_QUEUE: "csvQueue",
} as const
export const LOCAL_STORAGE_KEY = {
  GUEST_URL_KEY: "guestUrlKey",
  EMAIL: "email",
  PASSWORD: "password",
  CLIENT_TABLE_COLUMNS: "clientTableColumns",
  CLIENT_TABLE_SCHEMA_VERSION: "clientTableSchemaVersion",
  REGISTER_FORM: "registerForm",
  LAST_ACCESSED_AT: "lastAccessedAt",
} as const
export const OBJECT_LOCAL_STORAGE_KEY = {
  REGISTER_FORM: true,
  CLIENT_TABLE_COLUMNS: true,
} as const
export const CLIENT_CSV_TYPE = {
  CREATE: "create",
  UPDATE: "update",
}
export const CLIENT_DATA_SOURCE = {
  // 検索画面の「URLを共有する」からの登録
  // 2021年途中から存在しない
  BROWSER: "browser",
  // CSVからの登録
  CSV: "csv",
  // 反響自動登録
  HANKYO_AUTO: "hankyoAuto",
  // 反響自動登録できなかったレコードを手動登録
  HANKYO_MANUAL: "hankyoManual",
  // 法人ユーザー一覧からの追加
  ADMIN: "admin",
  // 物件フォームの「見学予約」
  PREVIEW_RESERVATION: "previewReservation",
  // 物件フォームの「問い合わせ」
  QUESTION: "question",
} as const
export const CLIENT_DATA_SOURCE_LABEL = {
  BROWSER: "WEB登録",
  CSV: "まとめて登録",
  HANKYO_AUTO: "自動登録",
  HANKYO_MANUAL: "入力",
  QUESTION: "問い合わせ",
  ADMIN: "管理者",
} as const
export const CLIENT_DATA_PARSE_RESULT_TYPE = {
  SUCCESS: "success",
  WARNING: "warning",
  FAILURE: "failure",
} as const
export const CLIENT_TABLE_COLUMN_TYPE = {
  INTERACTION_STATUS: "INTERACTION_STATUS",
  LATEST_HOT_ACTION_DATETIME: "LATEST_HOT_ACTION_DATETIME",
  MANIPULATION: "MANIPULATION",
  CREATED_AT: "CREATED_AT",
  CLIENT_NAME: "CLIENT_NAME",
  EMAIL: "EMAIL",
  PHONE: "PHONE",
  DEAL_TYPE: "DEAL_TYPE",
  DEAL_PHASE: "DEAL_PHASE",
  DATA_SOURCE: "DATA_SOURCE",
  MEMO: "MEMO",
  LATEST_ACTION_DATETIME: "LATEST_ACTION_DATETIME",
  LATEST_MAIL_DATETIME: "LATEST_MAIL_DATETIME",
  CONSULTATION_DATA_REQUEST_NUM: "CONSULTATION_DATA_REQUEST_NUM",
  // LOG_NUM: "LOG_NUM",
  // SEARCH_LOG_NUM: "SEARCH_LOG_NUM",
  // URL_SHARE_CLICK_RATE: "URL_SHARE_CLICK_RATE",
  // URL_SHARE_CLICK_NUM: "URL_SHARE_CLICK_NUM",
  MAIL_OPEN_RATE: "MAIL_OPEN_RATE",
  MAIL_OPEN_NUM: "MAIL_OPEN_NUM",
  MAIL_DELIVERED_NUM_CURRENT_MONTH: "MAIL_DELIVERED_NUM_CURRENT_MONTH",
  MAIL_DELIVERED_NUM: "MAIL_DELIVERED_NUM",
  STATUS: "STATUS",
  LOCATION: "LOCATION",
  PROPERTY_TYPE: "PROPERTY_TYPE",
  EXCLUSIVE_AREA: "EXCLUSIVE_AREA",
  CONTRACT_UNIT_PRICE: "CONTRACT_UNIT_PRICE",
  CONTRACT_PRICE: "CONTRACT_PRICE",
  EXCLUSIVE_AREA_RANGE: "EXCLUSIVE_AREA_RANGE",
  LAND_AREA_RANGE: "LAND_AREA_RANGE",
  FLOOR: "FLOOR",
  FLOOR_PLAN_NUMBER_OF_ROOMS: "FLOOR_PLAN_NUMBER_OF_ROOMS",
  ABOVE_GROUND_FLOOR_LEVELS: "ABOVE_GROUND_FLOOR_LEVELS",
  CONSTRUCTION_YEARMONTH: "CONSTRUCTION_YEARMONTH",
  OWNER_CHANGE: "OWNER_CHANGE",
  CONTRACT_DATE: "CONTRACT_DATE",
  SALE_UPDATE_DATE: "SALE_UPDATE_DATE",
  EMAIL_SEND_FROM_ID: "EMAIL_SEND_FROM_ID",
} as const
export const CLIENT_TABLE_COLUMN_LABEL_NAME_MAP = {
  LATEST_HOT_ACTION_DATETIME: "有望アクション日時",
  MANIPULATION: "操作",
  CLIENT_NAME: "名前",
  STATUS: "配信ステータス",
  CONSULTATION_DATA_REQUEST_NUM: "資料請求数",
  URL_SHARE_CLICK_RATE: "クリック率",
  URL_SHARE_CLICK_NUM: "クリック数",
  MAIL_OPEN_RATE: "開封率",
  MAIL_OPEN_NUM: "開封数",
  MAIL_DELIVERED_NUM: "配信数",
  MAIL_DELIVERED_NUM_CURRENT_MONTH: "今月の配信数",
  LATEST_ACTION_DATETIME: "開封日時",
  LATEST_MAIL_DATETIME: "配信日時",
  CREATED_AT: "登録日時",
  LOG_NUM: "画面操作数",
  SEARCH_LOG_NUM: "エリア検索数",
  PROPERTY_TYPE: "物件種類",
  EXCLUSIVE_AREA: "参考面積",
  CONTRACT_UNIT_PRICE: "物件の㎡単価(万円)",
  CONTRACT_PRICE: "物件価格(万円)",
  EXCLUSIVE_AREA_RANGE: "専有/建物面積範囲",
  LAND_AREA_RANGE: "土地面積範囲",
  FLOOR: "所在階範囲",
  FLOOR_PLAN_NUMBER_OF_ROOMS: "部屋数",
  ABOVE_GROUND_FLOOR_LEVELS: "総階数範囲",
  CONSTRUCTION_YEARMONTH: "竣工年範囲",
  CONTRACT_DATE: "契約年",
  LOCATION: "住所",
  EMAIL: "メールアドレス",
  PHONE: "電話番号",
  MEMO: "メモ",
  EMAIL_SEND_FROM_ID: "担当者",
  OWNER_CHANGE: "オーナーチェンジ",
  SALE_UPDATE_DATE: "売出日",
  DATA_SOURCE: "登録方法",
  DEAL_TYPE: "取引種類",
  DEAL_PHASE: "取引フェーズ",
} as const
export const HANKYO_MAIL_DOMAINS_TO_WATCH = [
  "suumo",
  "homes",
  "ieul",
  "lvnmatch",
  "home4u",
] as const
export const HANKYO_MAIL_FROMS = {
  IEUL: "ieul-support@speee.jp",
  SUUMO: "system@jds.suumo.jp",
} as const
export const HANKYO_STATUS_TYPE = {
  NOT_CONVERTED: "notConverted",
  AUTO_CONVERTED: "autoConverted",
  MANUALLY_CONVERTED: "manuallyConverted",
} as const
export const HANKYO_STATUS_TYPE_LABEL = {
  NOT_CONVERTED: "失敗",
  AUTO_CONVERTED: "自動登録",
  MANUALLY_CONVERTED: "入力済み",
} as const
export const DEAL_TYPE = {
  BUY: "buy",
  SELL: "sell",
  RENT: "rent",
  MANAGE: "manage",
  UNKNOWN: "unknown",
} as const
export const DEAL_TYPE_REQUIRED = {
  BUY: "buy",
  SELL: "sell",
  RENT: "rent",
} as const
export const TYPE_FORM_NOTIFY_HEADER_TEXT =
  "ユーザーからフォームの回答がありました" as const
export const TYPE_FORM_URL_BASE = "https://pinrich.typeform.com/to" as const
export const TYPE_FORM_ID = {
  HANKYO_REPLY_SELL: "gR5q2HBb",
  HANKYO_REPLY_RENT: "mde4ooOV",
  MARKET_MAIL: "x1arjr8V",
  BUYER_MAIL: "x1arjr8V",
  DISTRIBUTION_MAIL: "x1arjr8V",
  BREAKDOWN_MAIL: "x1arjr8V",
  HANKYO_REPLY_BUY: "MQC9PUaG",
  UPDATED_PROPERTIES_PRICE: "AZH55jwk",
  AI_SATEI_RESULT: "UWUh0ghS",
  SELL_TOP: "lByo99QR",
  LOAN_RESULT: "qaVMxBKJ",
  CATCH_UP_PROPERTIES: "sCUoDMcJ",
  CATCH_UP_RENT_PROPERTIES: "ZqAeeopX",
  DATA_REQUEST_BUY: "d8PYCAmN",
  MORTGAGE_EXAMINATION_RECOMMEND: "Y1DmuG5x",
  AMAZON_GIFT_SELL: "bZi99uLj",
  AMAZON_GIFT_BUY: "ehK7afTI",
  KENGAKU_YOYAKU: "baevfuoG",
  SHITSUMON_UKETSUKE: "f5ZG3xb9",
  UPDATE_ACCOUNT_EMAIL: "xtsbDVCQ",
  OWNER_PAGE: "N8ASrMPP",
} as const
export const TYPE_FORM_LABEL = {
  HANKYO_REPLY_SELL: "売却・反響自動応対",
  MARKET_MAIL: "売却・マーケットレポート",
  BUYER_MAIL: "売却・マーケットレポート",
  DISTRIBUTION_MAIL: "売却・マーケットレポート",
  BREAKDOWN_MAIL: "売却・マーケットレポート",
  HANKYO_REPLY_BUY: "購入・反響自動応対",
  HANKYO_REPLY_RENT: "賃貸・反響自動応対",
  UPDATED_PROPERTIES_PRICE: "売却・査定更新提案",
  CATCH_UP_PROPERTIES: "購入・物件提案新着",
  CATCH_UP_RENT_PROPERTIES: "賃貸・物件提案新着",
  DATA_REQUEST_BUY: "購入・ピンリッチ資料請求",
  MORTGAGE_EXAMINATION_RECOMMEND: "購入・住宅ローン事前審査",
  AMAZON_GIFT_SELL: "売却・Amazonギフト券3万円",
  AMAZON_GIFT_BUY: "購入・Amazonギフト券3000円",
  KENGAKU_YOYAKU: "見学予約",
  SHITSUMON_UKETSUKE: "質問受付",
  HOUMON_SATEI: "訪問査定を予約",
} as const
export const DEAL_TYPES_LABEL = {
  BUY: "購入",
  SELL: "売却",
  RENT: "賃貸",
  MANAGE: "管理",
  UNKNOWN: "不明",
} as const
export const DEAL_TYPES_LABEL_REQUIRED = {
  BUY: "購入",
  SELL: "売却",
  RENT: "賃貸",
} as const
export const DEAL_PHASE = {
  PURSUING: "pursuing",
  DEALING: "dealing",
  FINISHED: "finished",
} as const
export const DEAL_PHASE_LABEL = {
  PURSUING: "追客中",
  DEALING: "取引中",
  FINISHED: "クローズ",
} as const
export const QUERY_WHERE_TYPE = {
  LIKE: "like",
  EQUALS: "equals",
  LESS_THAN: "lessThan", // <=
  GREATER_THAN: "greaterThan", // >=
} as const
export const CLIENT_PROPERTY_TYPE = {
  APARTMENT: "マンション",
  HOUSE: "戸建",
} as const
export const MIN_FILTER_VALUE = "0" as const
export const MAX_FILTER_VALUE = "99999999999" as const
export const MAX_PROPERTY_NUM = 40000 as const
export const CLIENT_PROPERTY_TYPE_FILTER_ITEMS = ["マンション", "戸建"] as const
export const CLIENT_PREVIEW_FILTER_LABEL_NAME_MAP = {
  CONTRACT_UNIT_PRICE: "単価",
  CONTRACT_PRICE: "価格",
  EXCLUSIVE_AREA: "広さ（専有／建物面積）",
  FLOOR: "所在階",
  FLOOR_PLAN_NUMBER_OF_ROOMS: "部屋数",
  ABOVE_GROUND_FLOOR_LEVELS: "総階数",
  CONSTRUCTION_YEARMONTH: "竣工年",
  CONTRACT_DATE: "契約年",
} as const
export const USER_TYPE = {
  CORPORATE_USER: "corporateUser",
  ANONYMOUS_USER: "anonymousUser",
} as const
export const CUSTOMER_STATE_TYPE = {
  EXPIRED: "EXPIRED", // 支払い有効期限外
  CANCELED: "CANCELED", // 解約済みかつ支払い有効期限内
  NEED_CHECK_PAYMENT: "NEED_CHECK_PAYMENT", // 今期の支払いが失敗している
  PAID: "PAID", // 今期の支払いが成功している
} as const
export const REGISTRATION_STEP = {
  NOT_REGISTERED: "NOT_REGISTERED",
  NEED_VERIFICATION: "NEED_VERIFICATION",
  COMPLETED: "COMPLETED", // 登録完了
} as const
export const FORM_TYPE = {
  LOGIN: "login",
  REGISTER: "register",
  PASSWORD_RESET: "passwordReset",
} as const
export const MIN_LENGTH = {
  PASSWORD: 8,
} as const
export const MAX_LENGTH = {
  CORPORATE_NAME: 30,
  USER_NAME: 30,
  CSV_UPLOAD_ROWS: 5000,
} as const
export const AUTH_MESSAGE_TYPE = {
  USER: "user",
  ATTRIBUTES: "attributes",
  PASSWORD: "password",
} as const
export const ROLE_TYPE = {
  ADMIN: "admin",
} as const
export const POLYGON_TYPE = {
  ELE_SCHOOL: "eleSchool",
  JH_SCHOOL: "jhSchool",
  FLOOD: "flood",
  TSUNAMI: "tsunami",
  SEDIMENT_DISASTER: "sedimentDisaster",
  USE_DISTRICT: "useDistrict",
} as const
// 消す
// SCHOOL_TYPE: {},
// HAZARD_TYPE: {},
export const URL_SAVE_STATE_KEYS = [
  "sateiTarget",
  "filter",
  "activeFilter",
] as const
export const USER_ACTION_LOG_EVENT_TYPE = {
  // 事例検索ボタン押下
  PROPERTIES_SEARCH_BUTTON: "propertiesSearchButton",
  // フィルター設定ログ
  FILTER_SETTING: "filterSetting",
  // 事例選択ログ（A,B,Cではなく1件だけのハイライト）
  PROPERTY_SELECT: "propertySelect",
  // 事例参考（A,B,Cアルファベットの選択or外し）
  PROPERTY_REFER: "propertyRefer",
  // 条件変更ボタンクリック
  CHANGE_SEARCH_PROPERTY_CONDITION: "changeSearchPropertyCondition",
  // ポリゴンデータ取得
  GET_POLYGON_DATA: "getPolygonData",
  // メールからの相談ボタン押下
  CONSULTATION_REQUEST: "consultationRequest",
  // URL共有の変更
  UPDATE_URL_SHARE: "updateUrlShare",
} as const
export const TOO_OLD_SALE_PROPERTY_DATE = 60 as const
export const CONSULTATION_DATA_REQUEST_FROM = {
  EMAIL: "email",
  MAP: "map",
  LIST: "list",
} as const
export const PROFILE_DEFAULT_IMAGE_URL =
  "https://pinrich-files.s3-ap-northeast-1.amazonaws.com/profile.png" as const
export const TAG_TYPE_DATE = {
  // 7days/xxxmsec
  NEW: 604800000,
  // 14days
  PRICE_DROP: 1209600000,
  // 60days
  OLD_START: 5184000000,
  // 120days
  OLD_END: 10368000000,
  // 120days
  SOLD: 10368000000,
  // 120days
  INVALID: 10368000000,
} as const
export const LIST_APPEARANCE_TYPE = {
  PHOTOS: "Photos",
  TABLE: "Table",
} as const
export const SORT_TYPE_OPTIONS = {
  NEW: {
    text: "新着順",
    value: "NEW",
  },
  // UPDATED_AT: {
  //   text: "新着順",
  //   value: "UPDATED_AT"
  // },
  PRICE: {
    text: "価格が高い順",
    value: "PRICE",
  },
  PRICE_ASC: {
    text: "価格が低い順",
    value: "PRICE_ASC",
  },
  AREA: {
    text: "面積が大きい順",
    value: "AREA",
  },
  // AREA_ASC: "面積が小さい順",
  CONSTRUCTION_YEARMONTH: {
    text: "竣工年が新しい順",
    value: "CONSTRUCTION_YEARMONTH",
  },
  // YEAR_ASC: "竣工年が古い順"
} as const
// 画像のキー 41532-exterior
export const EXTERIOR_IMAGE_SUFFIX = "exterior" as const // 建物外観
export const BUILDING_IMAGE_SUFFIX = "building" as const // 建物周辺
export const EXTERIOR_IMAGE_TYPE = "建物外観" as const
export const IMAGE_TYPE_BY_GROUP = {
  room: [
    "間取り図",
    "玄関",
    "洗面設備",
    "居室・リビング",
    "収納",
    "バルコニー",
    "バス・シャワールーム",
    "トイレ",
    "キッチン",
    "眺望",
    "セキュリティ",
    "エアコン",
  ],
  building: [
    "建物外観",
    "エントランス",
    "ロビー",
    "駐車場",
    "駐輪場",
    "庭",
    "公園",
  ],
  others: [],
} as const
export const STRIPE_TAX_RATE_TEST = "txr_1IWLfIIALT3u9taTHNJSskIQ" as const
export const STRIPE_TAX_RATE = "txr_1FHmwVIALT3u9taT4glSxog4" as const
// バージョンを変えるとテーブルに表示するカラムとその順番を強制アップデートする
export const CLIENT_TABLE_SCHEMA_VERSION = 6 as const
export const NOTION_PAGE_ID = {
  notice: "5f264d2cb7834eedba805cc18e400737",
  functions: "9d2e098522ee43dda5c74c6a14b10225",
  about_us: "5d030f9a85774d4f8b52482737295944",
} as const

export const AI_SATEI_ASSESSMENT_TYPE = {
  REQUEST: "request",
  DO_NOT_REQUEST: "doNotRequest",
  OTHER_COMPANY: "otherCompany",
}

export const FORM_QUESTION_TYPE = {
  PHOTO: "photo",
  DETAIL_ADDRESS: "detail",
  PARKING_LOT: "parkingLot",
  PET: "pet",
  REFORM: "reform",
  RENOVATION: "renovation",
}

export const FORM_QUESTION_LABEL = {
  PHOTO: "物件写真・図面がほしい",
  DETAIL_ADDRESS: "詳細の住所が知りたい",
  PARKING_LOT: "駐車場の空き状況が知りたい",
  PET: "ペットについて",
  REFORM: "リフォームについて",
  RENOVATION: "リノベーションについて",
}

export const HANKYO_MAIL_TYPE = {
  SUMAI_STEP: "sumaiStep",
  HOME4U: "home4u",
  OUCHI: "ouchi",
  SUMO: "sumo",
  ZUBAT: "zubat",
} as const

export const HANKYO_SITE_SETTING_LABEL = {
  SUMAI_STEP: {
    title: "すまいステップ",
    loginId: "ID(メールアドレス)",
  },
  HOME4U: {
    title: "HOME4U",
    loginId: "ログインID",
  },
  OUCHI: {
    title: "おうちクラベル",
    loginId: "ログインID",
  },
  SUMO: {
    title: "スーモ売却",
    loginId: "ログインID",
  },
  ZUBAT: {
    title: "ズバット売却",
    loginId: "ログインID",
  },
} as const

export const SOURCE_LOGIN_URL = {
  SUMAI_STEP: "https://sumai-step.com/partner/login",
  OUCHI: "https://satei.partners-od.jp/login",
  SUMO: "https://jds.suumo.jp",
  ZUBAT: "https://real-estate-kanri.zba.jp",
} as const

export const AVATAR_COLOR = {
  sell: "blue",
  buy: "orange",
  rent: "green",
} as const

export const CALENDAR_MIN_HOUR = 6
export const CALENDAR_MAX_HOUR = 23
export const CALENDAR_MINUTE_STEP = 30

export const AWS_SES_VERIFY_STATUS = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  TEMPORARY_FAILURE: "TEMPORARY_FAILURE",
  NOT_STARTED: "NOT_STARTED",
} as const

export const MAX_SMS_SENT_PER_MONTH = 300

export const CLIENT_HANKYO_SOURCE = {
  ieul: "イエウール",
  suumoSell: "スーモ売却",
  suumo: "スーモ",
  homesSell: "HOMES売却",
  homes: "HOMES",
  lvnmatch: "リビンマッチ",
  home4u: "HOME4U",
  sumaiStep: "すまいステップ",
  smocca: "スモッカ",
  howMa: "ハウマAI査定",
  iekatuLife: "いえかつ",
  athome: "athome",
  sateiIe: "サテイエ",
  ouchi: "おうちクラベル",
  canary: "カナリー",
  chintai: "CHINTAI",
  mansionResearch: "マンションリサーチ",
  zubat: "ズバット売却",
  others: "その他",
}
